<mat-sidenav-container>
  <mat-sidenav-content>
    <div class="financials-panel" *ngIf="showFinancials">
      <div class="flex w-full gap-4 cards-panel" cdkDropList
           cdkDropListOrientation="horizontal"
           (cdkDropListDropped)="rearrangeFinancials($event)"
           [cdkDropListData]="enabledCards"
           [cdkDropListDisabled]="!scopeApprovalFlowService.isScopeEditable(scopeVersion)"
           *ngIf="this.scopeVersion">

        <ng-container *ngFor="let card of enabledCards | sortByOrder">
          <div *ngIf="card.ngIf() || card.addCardNgIf?.()"
               [ngStyle]="{'cursor': showFinancialsSettings ? 'pointer' : 'default'}"
               cdkDrag [cdkDragData]="card" class="card-container"
               [cdkDragDisabled]="!showFinancialsSettings || !scopeApprovalFlowService.isScopeEditable(scopeVersion)"
               [hidden]="(card.disabled && !showFinancialsSettings) || (card.disabled && !scopeApprovalFlowService.isScopeEditable(scopeVersion))">
            <mat-icon class="material-symbols-rounded orange minus-icon" *ngIf="showFinancialsSettings && card.disabled && scopeApprovalFlowService.isScopeEditable(scopeVersion)" (click)="card.disabled = false; ">add</mat-icon>
            <mat-icon class="material-symbols-rounded orange minus-icon" *ngIf="showFinancialsSettings && !card.disabled && scopeApprovalFlowService.isScopeEditable(scopeVersion)" (click)="card.disabled = true; ">remove</mat-icon>
            <button
              *ngIf='card.addCardNgIf?.()'
              class='financials-add-btn'
              [matTooltip]="scopeApprovalFlowService.isScopeEditable(scopeVersion) ? null : 'Scope is not editable'"
              (click)='scopeApprovalFlowService.isScopeEditable(scopeVersion) && card.add && card.add()'>
              <mat-icon class="material-symbols-rounded">add</mat-icon>
              Add {{card.addButtonLabel}}
            </button>
            <scope-ui-financial-card *ngIf="card.ngIf()"
                                     [heading]="card.heading"
                                     [sub_heading]="card.sub_heading?.()"
                                     [value]="isNumber(card.value()) ? (card.value() | currency: scopeVersion.getRateCardCurrency()) : card.value()"
                                     [sub_value]="card.sub_value?.()"
                                     [showMenu]="card.showMenu?.()"
                                     (edit)="card.edit && card.edit()"
                                     (delete)="card.delete && card.delete(card)"
                                     [expandable]="card.expandable"
                                     [headingClass]="card.headingClass"
                                     [showAddButton]="card.showAddButton?.()"
                                     [addButtonLabel]="card.addButtonLabel"
                                     [(expanded)]="card.expanded"
                                     (expandedChange)="card.expandedChange && card.expandedChange($event)"
                                     (add)="card.add && card.add()"
                                     [cardClass]="card.cardClass"
                                     [sub_value_label]="card.sub_value_label"
            ></scope-ui-financial-card>
          </div>
        </ng-container>
      </div>
      <div class="mt-6" *ngIf="showFinancialsFees && allFeesLoaded">
        <scope-ui-table
          [has-setting]="'FEE_ITEM__ENABLE&&SCOPE__ENABLE_FEES'"
          [has-privilege]="'SCOPE__VIEW_FEES'"
          [loggedInUser]='currentUser'
          [tableClass]="'fees-table'"
          [headerExpandable]="false"
          [dataSource]="allFees$ | async"
          [displayedColumns]="scopeTabService.scopeByRoleFeeColumns"
          [noSort]="false"
          [noPagination]="true"
          [noPreferences]="true"
          [showAddButton]="scopeApprovalFlowService.isScopeEditable(scopeVersion) && currentUser.hasPrivilege('SCOPE__CREATE_FEES')"
          [expandable]="false"
          (addClicked)="addFee()"
          [showToggleMenu]="feeMenuOptions.length > 0"
          [menuOptions]="feeMenuOptions"
          (updateFeeItem)="updateFeeItem($event)"
          [showFooterRow]="false">
        </scope-ui-table>
      </div>
      <div class="mt-6" *ngIf="showFinancialsFees && !allFeesLoaded">
        <div class="skeleton-loader">
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
        </div>
      </div>
      <div class="mt-6" *ngIf="showTimelineTpcs && allTpcsLoaded">
        <scope-ui-table
          [loggedInUser]='currentUser'
          [tableClass]="'tpc-table'"
          [headerExpandable]="false"
          [dataSource]="allThirdPartyCosts$ | async"
          [displayedColumns]="thirdPartyCostColumns"
          [noSort]="false"
          [noPagination]="true"
          [noPreferences]="true"
          [showToggleMenu]="false"
          [expandable]="false"
          [deleteAction]="scopeTpcDeleteAction"
          [showFooterRow]="false"
          (updateScopeTpc)="updateScopeThirdPartyCost($event)">
        </scope-ui-table>
      </div>
      <div class="mt-6" *ngIf="showTimelineTpcs && !allTpcsLoaded">
        <div class="skeleton-loader">
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-end mx-10 my-2 items-center">
      <button
        *ngIf="!_showFilterView"
        class="text-link ml-4 mr-4"
        aria-label="Show financials"
        (click)="showFinancials = !showFinancials"
        onclick="this.blur()">
        <mat-icon class="material-symbols-rounded icon-small orange">{{showFinancials ? 'visibility_off' : 'visibility'}}</mat-icon>
        {{showFinancials ? 'Hide Financials' : 'Show Financials'}}
      </button>
      <button
        *ngIf="!_showFilterView && scopeApprovalFlowService.isScopeEditable(scopeVersion)"
        class="text-link mr-4" [ngClass]="{'orange': showFinancialsSettings}"
        aria-label="Show financials"
        (click)="showFinancialsSettings = !showFinancialsSettings; showFinancialsSettings ? null : reinitCards();"
        onclick="this.blur()">
        <mat-icon class="material-symbols-rounded icon-small orange">{{showFinancialsSettings ? 'save' : 'settings'}}</mat-icon>
        {{showFinancialsSettings ? 'Save' : 'Financials settings'}}
      </button>
      <scope-ui-options-separated-menu
        *ngIf="scopeApprovalFlowService.isScopeEditable(scopeVersion)"
        [buttonText]="'Add task'"
        [menuOptions]="scopeActions"
        [secondaryMenuOptions]="secondaryScopeActions"
        (onClickEvent)="addTask()"
        (onOptionClickEvent)="onOptionClick($event)" />
      <button
        class="scope-actions-button"
        (click)="$event.stopPropagation()"
        [matMenuTriggerFor]="scopeOptionsMenu">
        <mat-icon class="material-symbols-rounded">more_vert</mat-icon>
      </button>
      <mat-menu
        #scopeOptionsMenu="matMenu"
        backdropClass="scope-options-menu">
        <ng-container *ngFor="let option of scopeOptions">
          <button
            (click)="option.click()"
            *ngIf="option.isVisible ? option.isVisible(scopeVersion) : true"
            mat-menu-item
            class="scope-option">
            <mat-icon class="material-symbols-rounded orange">{{ option.icon }}</mat-icon>
            <span class="scope-option-name">{{ option.name }}</span>
          </button>
        </ng-container>
      </mat-menu>
    </div>
    <mat-divider></mat-divider>

    <div class='flex flex-row mx-6.5'>
      <div class="flex flex-col flex-1 roles-container {{tableExpanded ? 'expanded' : ''}} {{showFinancials ? '' : 'hide-financials'}}">
        <scope-by-role-table
          [currentUser]="currentUser"
          [currentRatecard]="currentRatecard"
          [scopeVersion]="scopeVersion"
          [tableExpanded]="tableExpanded"
          (tableExpandedChange)="tableExpanded = $event"
          [(departmentSort)]="departmentSort">
        </scope-by-role-table>
        <div class="role-footer {{tableExpanded ? 'expanded' : ''}}">
          <ng-container *ngIf="scopeApprovalFlowService.isScopeEditable(scopeVersion) && currentRatecard?.getTotalRolesCount() as ratecardRolesCount">
            <ng-container *ngIf="getTotalRolesCount() as totalRolesCount; else no_roles">
              <button *ngIf="ratecardRolesCount !== totalRolesCount" class="text-link mt-2 add-item-button"
                      mat-button (click)="addRoles()">
                <mat-icon class="material-symbols-rounded">assignment_ind</mat-icon>Add/Remove Roles
              </button>
              <button *ngIf="ratecardRolesCount === totalRolesCount" class="text-link mt-2 add-item-button"
                      mat-button (click)="addRoles()">
                <mat-icon class="material-symbols-rounded">person_remove</mat-icon>Remove Roles
              </button>
            </ng-container>
            <ng-template #no_roles>
              <button class="text-link mt-2 add-item-button"
                      mat-button (click)="addRoles()">
                <mat-icon class="material-symbols-rounded">assignment_ind</mat-icon>Add Roles
              </button>
            </ng-template>
          </ng-container>
        </div>
      </div>
      <div class="flex flex-row tasks-container {{tableExpanded ? 'expanded' : ''}} {{showFinancials ? '' : 'hide-financials'}}">
        <scope-by-role-task
          *ngFor="let deliverable of scopeVersion.deliverables; trackBy: trackById"
          [deliverable]="deliverable"
          [currentUser]="currentUser"
          [currentRatecard]="currentRatecard"
          [scopeVersion]="scopeVersion"
          [tableExpanded]="tableExpanded"
          [departmentSort]="departmentSort"
          (tableExpandedChange)="tableExpanded = $event"
          (deleteTask)="deleteDeliverable(deliverable)" >
        </scope-by-role-task>
        <div class="empty-task" *ngIf="!scopeVersion.deliverables.length">
          <div class="task-main h-full">
            <div class="name-container opacity-50">
              <div class="task-name cursor-default">Add name</div>
              <button
                class="scope-actions-button cursor-default">
                <mat-icon class="material-symbols-rounded cursor-default">more_vert</mat-icon>
              </button>
            </div>
            <div class="task-table h-full">
              <div class="task-header text-scope_purple opacity-50">
                <span class="header-value">% FTE</span>
                <span class="header-value">Hours</span>
              </div>
              <div *ngIf="scopeApprovalFlowService.isScopeEditable(scopeVersion)" class="flex justify-center items-center h-full pb-8">
                <button class="mx-2 add-task-button" (click)="addTask()">
                  Add Task
                </button>
              </div>
            </div>

            <div class="task-footer-wrapper opacity-50">
              <div class="task-footer {{tableExpanded ? 'expanded' : ''}}">
                <div>
                  <label>Quantity</label>
                  <span>1</span>
                </div>
                <mat-divider></mat-divider>
                <div>
                  <label>Margin</label>
                  <span>0%</span>
                </div>
                <mat-divider></mat-divider>
                <div>
                  <label>Cost Price</label>
                  <span>0</span>
                </div>
                <mat-divider></mat-divider>
                <div>
                  <label>Selling Price</label>
                  <span>0</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
