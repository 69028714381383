import { createAction, emptyProps, props } from '@ngrx/store'
import { Pagination } from '@core/model/definitions/pagination.interface'
import { DeliverablePreferences, ScopePreferences } from '../../models/scope-columns.model';
import { Sort } from '@angular/material/sort'
import { ScopeFilter } from '@core/model/scope-filter.model'
import { Page } from '@app/features/scoping/models/page.model'
import { ScopeVersion } from "@core/model/scope-version"
import { FolderVersion } from '@core/model/folder-version.model'
import { FindRolesSearchParams } from '@app/core/model/definitions/findRoleSearchParams.interface'
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model';
import { ScopeDynamicFieldSettingModel } from '@app/features/scoping/models/scope-dynamic-field-setting.model';

export enum ScopesActionTypes {
  GET_MY_SCOPES = '[My Scopes] Get My Scopes',
  GET_MY_SCOPES_FAIL = '[My Scopes] Get My Scopes Fail',
  GET_MY_SCOPES_SUCCESS = '[My Scopes] Get My Scopes Success',
  GET_ARCHIVED_SCOPES = '[My Scopes] Get Archived Scopes',
  GET_ARCHIVED_SCOPES_FAIL = '[My Scopes] Get Archived Scopes Fail',
  GET_ARCHIVED_SCOPES_SUCCESS = '[My Scopes] Get Archived Scopes Success',
  GET_FOLDERS = '[My Scopes] Get Folders',
  GET_FOLDERS_FAIL = '[My Scopes] Get Folders Fail',
  GET_FOLDERS_SUCCESS = '[My Scopes] Get Folders Success',
  GET_ARCHIVED_FOLDERS = '[My Scopes] Get Archived Folders',
  GET_ARCHIVED_FOLDERS_FAIL = '[My Scopes] Get Archived Folders Fail',
  GET_ARCHIVED_FOLDERS_SUCCESS = '[My Scopes] Get Archived Folders Success',
  GET_HIGHLIGHTS = '[My Scopes] Get Highlights',
  GET_HIGHLIGHTS_FAIL = '[My Scopes] Get Highlights Fail',
  GET_HIGHLIGHTS_SUCCESS = '[My Scopes] Get Highlights Success',
  SEARCH_DELIVERABLES = '[My Scopes] Search Deliverables',
  SEARCH_DELIVERABLES_FAIL = '[My Scopes] Search Deliverables Fail',
  SEARCH_DELIVERABLES_SUCCESS = '[My Scopes] Search Deliverables Success',
  SEARCH_COMPONENTS = '[My Scopes] Search Components',
  SEARCH_COMPONENTS_FAIL = '[My Scopes] Search Components Fail',
  SEARCH_COMPONENTS_SUCCESS = '[My Scopes] Search Components Success',
  GET_MY_SCOPES_COLUMNS = '[My Scopes] Get My Scopes Mapped Columns',
  GET_MY_SCOPES_COLUMNS_SUCCESS = '[My Scopes] Get My Scopes Mapped Columns Success',
  GET_SCOPE_COLUMNS_SUCCESS = '[My Scopes] Get Scope Mapped Columns Success',
  FILTER_SCOPES_SORT = '[My Scopes] Filter scopes by sort',
  FILTER_SCOPES_PAGE_INFO = '[My Scopes] Filter scopes by page index and size',
  FILTER_ARCHIVED_SCOPES_SORT = '[My Scopes] Sort archived scopes',
  FILTER_ARCHIVED_SCOPES_PAGE_INFO = '[My Scopes] Filter archived scopes by page index and size',
  FILTER_SCOPES = '[My Scopes] Filter scopes by user selected filters',
  FILTER_FOLDERS_SORT = '[My Scopes] Filter folders by sort',
  FILTER_FOLDERS_PAGE_INFO = '[My Scopes] Filter folders by page index and size',
  FILTER_ARCHIVED_FOLDERS_SORT = '[My Scopes] Sort archived folders',
  FILTER_ARCHIVED_FOLDERS_PAGE_INFO = '[My Scopes] Filter archived folders by page index and size',
  FILTER_FOLDERS = '[My Scopes] Filter folders by user selected filters',
  GET_DELIVERABLE_SUMMARIES = '[My Scopes] Get Deliverable Summaries',
  GET_DELIVERABLE_SUMMARIES_FAIL = '[My Scopes] Get Deliverable Summaries Fail',
  GET_DELIVERABLE_SUMMARIES_SUCCESS = '[My Scopes] Get Deliverable Summaries Success',
  GET_DELIVERABLES = '[My Scopes] Get Deliverables',
  GET_DELIVERABLES_FAIL = '[My Scopes] Get Deliverables Fail',
  GET_DELIVERABLES_SUCCESS = '[My Scopes] Get Deliverables Success',
  GET_SCOPES_BY_FOLDER = '[My Scopes] Get Scopes By Folder',
  GET_SCOPES_BY_FOLDER_FAIL = '[My Scopes] Get Scopes By Folder Fail',
  GET_SCOPES_BY_FOLDER_SUCCESS = '[My Scopes] Get Scopes By Folder Success',
  ARCHIVE_SCOPE = '[My Scopes] Archive Scope',
  ARCHIVE_SCOPE_FAIL = '[My Scopes] Archive Scope Fail',
  ARCHIVE_SCOPE_SUCCESS = '[My Scopes] Archive Scope Success',
  UNARCHIVE_SCOPE = '[My Scopes] Unarchive Scope',
  UNARCHIVE_SCOPE_FAIL = '[My Scopes] Unarchive Scope Fail',
  UNARCHIVE_SCOPE_SUCCESS = '[My Scopes] Unarchive Scope Success',
  ARCHIVE_SOW = '[My Scopes] Archive Scope Of Work',
  ARCHIVE_SOW_FAIL = '[My Scopes] Archive Scope Of Work Fail',
  ARCHIVE_SOW_SUCCESS = '[My Scopes] Archive Scope Of Work Success',
  UNARCHIVE_SOW = '[My Scopes] Unarchive Scope Of Work',
  UNARCHIVE_SOW_FAIL = '[My Scopes] Unarchive Scope Of Work Fail',
  UNARCHIVE_SOW_SUCCESS = '[My Scopes] Unarchive Scope Of Work Success',
  CLOSE_SCOPE = '[My Scopes] Close Scope',
  CLOSE_SCOPE_FAIL = '[My Scopes] Close Scope Fail',
  CLOSE_SCOPE_SUCCESS = '[My Scopes] Close Scope Success',
  DELETE_SCOPE = '[My Scopes] Delete Scope',
  DELETE_SCOPE_FAIL = '[My Scopes] Delete Scope Fail',
  DELETE_SCOPE_SUCCESS = '[My Scopes] Delete Scope Success',
  DELETE_FOLDER = '[My Scopes] Delete Folder',
  DELETE_FOLDER_FAIL = '[My Scopes] Delete Folder Fail',
  DELETE_FOLDER_SUCCESS = '[My Scopes] Delete Folder Success',
  GET_ALL_FOLDERS = '[My Scopes] Get All Folders',
  GET_ALL_FOLDERS_FAIL = '[My Scopes] Get All Folders Fail',
  GET_ALL_FOLDERS_SUCCESS = '[My Scopes] Get All Folders Success',
  GET_SECOND_PARTIES = '[My Scopes] Get Second Parties',
  GET_SECOND_PARTIES_FAIL = '[My Scopes] Get Second Parties Fail',
  GET_SECOND_PARTIES_SUCCESS = '[My Scopes] Get Second Parties Success',
  ASSIGN_SCOPE_TO_FOLDER = '[My Scopes] Assign Scope To Folder',
  ASSIGN_SCOPE_TO_FOLDER_FAIL = '[My Scopes] Assign Scope To Folder Fail',
  ASSIGN_SCOPE_TO_FOLDER_SUCCESS = '[My Scopes] Assign Scope To Folder Success',
  UNASSIGN_SCOPE_FROM_FOLDER = '[My Scopes] Unassign Scope From Folder',
  UNASSIGN_SCOPE_FROM_FOLDER_FAIL = '[My Scopes] Unassign Scope From Folder Fail',
  UNASSIGN_SCOPE_FROM_FOLDER_SUCCESS = '[My Scopes] Unassign Scope From Folder Success',
  FIND_ROLES = '[Find and Replace] Find Roles',
  FIND_ROLES_SUCCESS = '[Find and Replace] Find Roles Fail',
  FIND_ROLES_FAIL = '[Find and Replace] Find Roles Success',
  SET_SELECTED_REPLACE_ROLE = '[Find and Replace] Set Selected Replace Role',
  SET_SELECTED_REPLACE_WITH_ROLE = '[Find and Replace] Set Selected Replace With Role',
  REPLACE_ROLES = '[Find and Replace] Replace roles submitted from form',
  REPLACE_ROLES_SUCCESS = '[Find and Replace] Replace roles submitted from form Success',
  REPLACE_ROLES_FAIL = '[Find and Replace] Replace roles submitted from form Fail',
  SET_REPLACE_SUMMARY_SUCCESS = '[Find and Replace] Trigger Find and Replaces Roles Success Screen',
  CLOSE_FIND_REPLACE = '[Find and Replace] Close Find Replace',
  GET_RATECARD_BY_SCOPE = '[Find and Replace] Get Ratecard for current scope',
  GET_RATECARD_BY_SCOPE_SUCCESS = '[Find and Replace] Get Ratecard for current scope Success',
  GET_RATECARD_BY_SCOPE_FAIL = '[Find and Replace] Get Ratecard for current scope Fail',
  GET_RATECARD = '[Find and Replace] Get Ratecard',
  GET_RATECARD_SUCCESS = '[Find and Replace] Get Ratecard Success',
  GET_RATECARD_FAIL = '[Find and Replace] Get Ratecard Fail',
  GET_OFFICE_LOCATIONS = '[Scopes Overview] Get Office Locations',
  GET_OFFICE_LOCATIONS_SUCCESS = '[Scopes Overview] Get Office Locations Success',
  GET_OFFICE_LOCATIONS_FAIL = '[Scopes Overview] Get Office Locations Fail',
  GET_SCOPE_TEMPLATES = '[Create Scope] Get Scope Templates',
  GET_SCOPE_TEMPLATES_SUCCESS = '[Create Scope] Get Scope Templates Success',
  GET_SCOPE_TEMPLATES_FAIL = '[Create Scope] Get Scope Templates Fail',
  GET_WORD_TEMPLATES = '[Create Scope] Get Word Templates',
  GET_WORD_TEMPLATES_SUCCESS = '[Create Scope] Get Word Templates Success',
  GET_WORD_TEMPLATES_FAIL = '[Create Scope] Get Word Templates Fail',
  GET_XLSX_TEMPLATES = '[Scopes Overview] Get Xlsx Templates',
  GET_XLSX_TEMPLATES_SUCCESS = '[Scopes Overview] Get Xlsx Templates Success',
  GET_XLSX_TEMPLATES_FAIL = '[Scopes Overview] Get Xlsx Templates Fail',
  GET_SCOPE_TYPES = '[Create Scope] Get Scope Types',
  GET_SCOPE_TYPES_SUCCESS = '[Create Scope] Get Scope Types Success',
  GET_SCOPE_TYPES_FAIL = '[Create Scope] Get Scope Types Fail',
  GET_CUSTOM_FIELDS = '[Create Scope] Get Custom Fields',
  GET_CUSTOM_FIELDS_SUCCESS = '[Create Scope] Get Custom Fields Success',
  GET_CUSTOM_FIELDS_FAIL = '[Create Scope] Get Custom Fields Fail',
  GET_TRAFFIC_SYSTEM = '[Traffic System] Get Traffic System',
  GET_TRAFFIC_SYSTEM_SUCCESS = '[Traffic System] Get Traffic System Success',
  GET_TRAFFIC_SYSTEM_FAIL = '[Traffic System] Get Traffic System Fail',
  REVERT_REPLACEMENTS = '[Find and Replace] Revert Role Replacements',
  REVERT_REPLACEMENTS_SUCCESS = '[Find and Replace] Revert Role Replacements Success',
  REVERT_REPLACEMENTS_FAIL = '[Find and Replace] Revert Role Replacements Fail',
  GET_ALL_FEE_ITEMS = '[Scope Overview] Get All Fee Items',
  GET_ALL_FEE_ITEMS_SUCCESS = '[Scope Overview] Get All Fee Items Success',
  GET_ALL_FEE_ITEMS_FAIL = '[Scope Overview] Get All Fee Items Fail',
  GET_ALL_SCOPE_DYNAMIC_FIELD_SETTINGS = '[Scoping] Load Dynamic Field Settings',
  GET_ALL_SCOPE_DYNAMIC_FIELD_SETTINGS_SUCCESS = '[Scoping] Load Dynamic Field Settings Success',
  GET_ALL_SCOPE_DYNAMIC_FIELD_SETTINGS_FAIL = '[Scoping] Load Dynamic Field Settings Fail',
  CREATE_SCOPE_DYNAMIC_FIELD_SETTING = '[Scoping] Create Dynamic Field Setting',
  CREATE_SCOPE_DYNAMIC_FIELD_SETTING_SUCCESS = '[Scoping] Create Dynamic Field Setting Success',
  CREATE_SCOPE_DYNAMIC_FIELD_SETTING_FAIL = '[Scoping] Create Dynamic Field Setting Fail',
  UPDATE_SCOPE_DYNAMIC_FIELD_SETTING = '[Scoping] Update Dynamic Field Setting',
  UPDATE_SCOPE_DYNAMIC_FIELD_SETTING_SUCCESS = '[Scoping] Update Dynamic Field Setting Success',
  UPDATE_SCOPE_DYNAMIC_FIELD_SETTING_FAIL = '[Scoping] Update Dynamic Field Setting Fail',
  DELETE_SCOPE_DYNAMIC_FIELD_SETTING = '[Scoping] Delete Dynamic Field Setting',
  DELETE_SCOPE_DYNAMIC_FIELD_SETTING_SUCCESS = '[Scoping] Delete Dynamic Field Setting Success',
  DELETE_SCOPE_DYNAMIC_FIELD_SETTING_FAIL = '[Scoping] Delete Dynamic Field Setting Fail'
}

const getMyScopes = createAction(ScopesActionTypes.GET_MY_SCOPES, props<{ params?: any }>())
const getMyScopesFail = createAction(ScopesActionTypes.GET_MY_SCOPES_FAIL, props<{ error: Error }>())
const getMyScopesSuccess = createAction(
  ScopesActionTypes.GET_MY_SCOPES_SUCCESS,
  props<{ scopes: Page<ScopeVersion> }>()
)

const getArchivedScopes = createAction(ScopesActionTypes.GET_ARCHIVED_SCOPES, props<{ params?: any }>())
const getArchivedScopesFail = createAction(ScopesActionTypes.GET_ARCHIVED_SCOPES_FAIL, props<{ error: Error }>())
const getArchivedScopesSuccess = createAction(
  ScopesActionTypes.GET_ARCHIVED_SCOPES_SUCCESS,
  props<{ scopes: Page<ScopeVersion> }>()
)

const getMyScopesMappedColumns = createAction(ScopesActionTypes.GET_MY_SCOPES_COLUMNS, props<{ params?: any }>())
const getMyScopesMappedColumnsSuccess = createAction(
  ScopesActionTypes.GET_MY_SCOPES_COLUMNS_SUCCESS,
  props<{ preferences: ScopePreferences }>()
)

const getScopeMappedColumnsSuccess = createAction(
  ScopesActionTypes.GET_SCOPE_COLUMNS_SUCCESS,
  props<{ preferences: DeliverablePreferences }>()
)

const getFolders = createAction(ScopesActionTypes.GET_FOLDERS, props<{ params?: any }>())
const getFoldersFail = createAction(ScopesActionTypes.GET_FOLDERS_FAIL, props<{ error: Error }>())
const getFoldersSuccess = createAction(ScopesActionTypes.GET_FOLDERS_SUCCESS, props<{ folders: Page<FolderVersion> }>())

const getArchivedFolders = createAction(ScopesActionTypes.GET_ARCHIVED_FOLDERS, props<{ params?: any }>())
const getArchivedFoldersFail = createAction(ScopesActionTypes.GET_ARCHIVED_FOLDERS_FAIL, props<{ error: Error }>())
const getArchivedFoldersSuccess = createAction(ScopesActionTypes.GET_ARCHIVED_FOLDERS_SUCCESS, props<{ folders: Page<FolderVersion> }>())

const getHighlights = createAction(ScopesActionTypes.GET_HIGHLIGHTS)
const getHighlightsFail = createAction(ScopesActionTypes.GET_HIGHLIGHTS_FAIL, props<{ error: Error }>())
const getHighlightsSuccess = createAction(ScopesActionTypes.GET_HIGHLIGHTS_SUCCESS, props<{ highlights: any }>())

const searchDeliverables = createAction(ScopesActionTypes.SEARCH_DELIVERABLES, props<{ searchText: string }>());
const searchDeliverablesFail = createAction(ScopesActionTypes.SEARCH_DELIVERABLES_FAIL, props<{ error: Error }>());
const searchDeliverablesSuccess = createAction(ScopesActionTypes.SEARCH_DELIVERABLES_SUCCESS, props<{ deliverables: any }>());

const searchComponents = createAction(ScopesActionTypes.SEARCH_COMPONENTS, props<{ searchText: string }>());
const searchComponentsFail = createAction(ScopesActionTypes.SEARCH_COMPONENTS_FAIL, props<{ error: Error }>());
const searchComponentsSuccess = createAction(ScopesActionTypes.SEARCH_COMPONENTS_SUCCESS, props<{ components: any }>());

const filterScopesSort = createAction(ScopesActionTypes.FILTER_SCOPES_SORT, props<{ sort: Sort }>());
const filterScopesPageInfo = createAction(ScopesActionTypes.FILTER_SCOPES_PAGE_INFO, props<{ pageInfo: Pagination }>());
const filterArchivedScopesSort = createAction(ScopesActionTypes.FILTER_ARCHIVED_SCOPES_SORT, props<{ sort: Sort }>());
const filterArchivedScopesPageInfo = createAction(ScopesActionTypes.FILTER_ARCHIVED_SCOPES_PAGE_INFO, props<{ pageInfo: Pagination }>());
const filterScopes = createAction(ScopesActionTypes.FILTER_SCOPES, props<{ filters: ScopeFilter, page?: number, pageArchived?: number }>());

const filterFoldersSort = createAction(ScopesActionTypes.FILTER_FOLDERS_SORT, props<{ sort: Sort }>())
const filterFoldersPageInfo = createAction(ScopesActionTypes.FILTER_FOLDERS_PAGE_INFO, props<{ pageInfo: Pagination }>())
const filterArchivedFoldersSort = createAction(ScopesActionTypes.FILTER_ARCHIVED_FOLDERS_SORT, props<{ sort: Sort }>())
const filterArchivedFoldersPageInfo = createAction(ScopesActionTypes.FILTER_ARCHIVED_FOLDERS_PAGE_INFO, props<{ pageInfo: Pagination }>())
const filterFolders = createAction(ScopesActionTypes.FILTER_FOLDERS, props<{ filters: ScopeFilter, page?: number, pageArchived?: number }>())

const getDeliverableSummaries = createAction(ScopesActionTypes.GET_DELIVERABLE_SUMMARIES, props<{ scopeId: number }>())
const getDeliverableSummariesFail = createAction(ScopesActionTypes.GET_DELIVERABLE_SUMMARIES_FAIL, props<{ error: Error }>())
const getDeliverableSummariesSuccess = createAction(
  ScopesActionTypes.GET_DELIVERABLE_SUMMARIES_SUCCESS,
  props<{ scopeId: number; deliverables: any }>()
)

const getDeliverables = createAction(ScopesActionTypes.GET_DELIVERABLES, props<{ scopeId: number }>())
const getDeliverablesFail = createAction(ScopesActionTypes.GET_DELIVERABLES_FAIL, props<{ error: Error }>())
const getDeliverablesSuccess = createAction(
  ScopesActionTypes.GET_DELIVERABLES_SUCCESS,
  props<{ scopeId: number; deliverables: any }>()
)

const getScopesByFolder = createAction(ScopesActionTypes.GET_SCOPES_BY_FOLDER, props<{ folderId: number }>())
const getScopesByFolderFail = createAction(ScopesActionTypes.GET_SCOPES_BY_FOLDER_FAIL, props<{ error: Error }>())
const getScopesByFolderSuccess = createAction(
  ScopesActionTypes.GET_SCOPES_BY_FOLDER_SUCCESS,
  props<{ folderId: number; scopes: any }>()
)

const archiveScope = createAction(ScopesActionTypes.ARCHIVE_SCOPE, props<{ scopeId: number }>())
const archiveScopeFail = createAction(ScopesActionTypes.ARCHIVE_SCOPE_FAIL, props<{ error: Error }>())
const archiveScopeSuccess = createAction(ScopesActionTypes.ARCHIVE_SCOPE_SUCCESS, props<{ scopeId: number }>())

const unarchiveScope = createAction(ScopesActionTypes.UNARCHIVE_SCOPE, props<{ scopeId: number }>())
const unarchiveScopeFail = createAction(ScopesActionTypes.UNARCHIVE_SCOPE_FAIL, props<{ error: Error }>())
const unarchiveScopeSuccess = createAction(ScopesActionTypes.UNARCHIVE_SCOPE_SUCCESS, props<{ scopeId: number }>())

const archiveSow = createAction(ScopesActionTypes.ARCHIVE_SOW, props<{ sowId: number }>())
const archiveSowFail = createAction(ScopesActionTypes.ARCHIVE_SOW_FAIL, props<{ error: Error }>())
const archiveSowSuccess = createAction(ScopesActionTypes.ARCHIVE_SOW_SUCCESS, props<{ sowId: number }>())

const unarchiveSow = createAction(ScopesActionTypes.UNARCHIVE_SOW, props<{ sowId: number }>())
const unarchiveSowFail = createAction(ScopesActionTypes.UNARCHIVE_SOW_FAIL, props<{ error: Error }>())
const unarchiveSowSuccess = createAction(ScopesActionTypes.UNARCHIVE_SOW_SUCCESS, props<{ sowId: number }>())

const closeScope = createAction(ScopesActionTypes.CLOSE_SCOPE, props<{ scopeId: number; comment: string }>())
const closeScopeFail = createAction(ScopesActionTypes.CLOSE_SCOPE_FAIL, props<{ error: Error }>())
const closeScopeSuccess = createAction(ScopesActionTypes.CLOSE_SCOPE_SUCCESS, props<{ scopeId: number }>())

const deleteScope = createAction(ScopesActionTypes.DELETE_SCOPE, props<{ scopeId: number }>())
const deleteScopeFail = createAction(ScopesActionTypes.DELETE_SCOPE_FAIL, props<{ error: Error }>())
const deleteScopeSuccess = createAction(ScopesActionTypes.DELETE_SCOPE_SUCCESS, props<{ scopeId: number }>())

const deleteFolder = createAction(ScopesActionTypes.DELETE_FOLDER, props<{ folderId: number }>())
const deleteFolderFail = createAction(ScopesActionTypes.DELETE_FOLDER_FAIL, props<{ error: Error }>())
const deleteFolderSuccess = createAction(ScopesActionTypes.DELETE_FOLDER_SUCCESS, props<{ folderId: number }>())

const getAllFolders = createAction(ScopesActionTypes.GET_ALL_FOLDERS, props<{ params?: any }>())
const getAllFoldersFail = createAction(ScopesActionTypes.GET_ALL_FOLDERS_FAIL, props<{ error: Error }>())
const getAllFoldersSuccess = createAction(
  ScopesActionTypes.GET_ALL_FOLDERS_SUCCESS,
  props<{ folders: FolderVersion[] }>()
)

const getSecondParties = createAction(ScopesActionTypes.GET_SECOND_PARTIES, emptyProps)
const getSecondPartiesFail = createAction(ScopesActionTypes.GET_SECOND_PARTIES_FAIL, props<{ error: Error }>())
const getSecondPartiesSuccess = createAction(
  ScopesActionTypes.GET_SECOND_PARTIES_SUCCESS,
  props<{ secondParties: any[] }>()
)

const assignScopeToFolder = createAction(
  ScopesActionTypes.ASSIGN_SCOPE_TO_FOLDER,
  props<{ scope: ScopeVersion; folder: FolderVersion }>()
)
const assignScopeToFolderFail = createAction(ScopesActionTypes.ASSIGN_SCOPE_TO_FOLDER_FAIL, props<{ error: Error }>())
const assignScopeToFolderSuccess = createAction(
  ScopesActionTypes.ASSIGN_SCOPE_TO_FOLDER_SUCCESS,
  props<{ scopeId: number; folder: FolderVersion }>()
)

const unassignScopeFromFolder = createAction(ScopesActionTypes.UNASSIGN_SCOPE_FROM_FOLDER, props<{ scopeId: number }>())
const unassignScopeFromFolderFail = createAction(
  ScopesActionTypes.UNASSIGN_SCOPE_FROM_FOLDER_FAIL,
  props<{ error: Error }>()
)
const unassignScopeFromFolderSuccess = createAction(
  ScopesActionTypes.UNASSIGN_SCOPE_FROM_FOLDER_SUCCESS,
  props<{ scopeId: number }>()
)

const findRoles = createAction(
  ScopesActionTypes.FIND_ROLES,
  props<{ scopeId: number; searchParams?: FindRolesSearchParams }>()
)
const findRolesFail = createAction(ScopesActionTypes.FIND_ROLES_FAIL, props<{ error: Error }>())
const findRolesSuccess = createAction(ScopesActionTypes.FIND_ROLES_SUCCESS, props<{ roles: Error }>())

const setSelectedReplaceRole = createAction(ScopesActionTypes.SET_SELECTED_REPLACE_ROLE, props<{ role: any }>())
const setSelectedReplaceWithRole = createAction(
  ScopesActionTypes.SET_SELECTED_REPLACE_WITH_ROLE,
  props<{ role: any }>()
)

const replaceRoles = createAction(
  ScopesActionTypes.REPLACE_ROLES,
  props<{ roles: any; scopeId: number; selectedRoleId: any; selectedRoleReplaceRoleId: any }>()
)

const replaceRolesSuccess = createAction(ScopesActionTypes.REPLACE_ROLES_SUCCESS, props<{ response: any }>())

const replaceRolesFail = createAction(ScopesActionTypes.REPLACE_ROLES_FAIL, props<{ error: Error }>())

const closeFindReplace = createAction(
  ScopesActionTypes.CLOSE_FIND_REPLACE,
  emptyProps
)

const setReplaceSummaryConfirmation = createAction(
  ScopesActionTypes.SET_REPLACE_SUMMARY_SUCCESS,
  props<{ isRolesReplaced: boolean }>()
)

const getRateCardForScope = createAction(ScopesActionTypes.GET_RATECARD_BY_SCOPE, props<{ scopeId: number }>())
const getRateCardForScopeSuccess = createAction(ScopesActionTypes.GET_RATECARD_BY_SCOPE_SUCCESS, props<{ ratecard: RatecardVersion }>())
const getRateCardForScopeFail = createAction(ScopesActionTypes.GET_RATECARD_BY_SCOPE_FAIL, props<{ error: Error }>())

const getRateCard = createAction(ScopesActionTypes.GET_RATECARD, props<{ rateCardId: string }>())
const getRateCardSuccess = createAction(ScopesActionTypes.GET_RATECARD_SUCCESS, props<{ ratecard: string }>())
const getRateCardFail = createAction(ScopesActionTypes.GET_RATECARD_FAIL, props<{ error: Error }>())

const getOfficeLocations = createAction(ScopesActionTypes.GET_OFFICE_LOCATIONS, emptyProps)
const getOfficeLocationsSuccess = createAction(ScopesActionTypes.GET_OFFICE_LOCATIONS_SUCCESS, props<{ locations: string }>())
const getOfficeLocationsFail = createAction(ScopesActionTypes.GET_OFFICE_LOCATIONS_FAIL, props<{ error: Error }>())

const getScopeTemplates = createAction(ScopesActionTypes.GET_SCOPE_TEMPLATES, emptyProps)
const getScopeTemplatesSuccess = createAction(ScopesActionTypes.GET_SCOPE_TEMPLATES_SUCCESS, props<{ scopeTemplates: string }>())
const getScopeTemplatesFail = createAction(ScopesActionTypes.GET_SCOPE_TEMPLATES_FAIL, props<{ error: Error }>())

const getWordOutputTemplates = createAction(ScopesActionTypes.GET_WORD_TEMPLATES, emptyProps)
const getWordOutputTemplatesSuccess = createAction(ScopesActionTypes.GET_WORD_TEMPLATES_SUCCESS, props<{ wordTemplates: string }>())
const getWordOutputTemplatesFail = createAction(ScopesActionTypes.GET_WORD_TEMPLATES_FAIL, props<{ error: Error }>())

const getXlsxOutputTemplates = createAction(ScopesActionTypes.GET_XLSX_TEMPLATES, emptyProps)
const getXlsxOutputTemplatesSuccess = createAction(ScopesActionTypes.GET_XLSX_TEMPLATES_SUCCESS, props<{ xlsxTemplates: string }>())
const getXlsxOutputTemplatesFail = createAction(ScopesActionTypes.GET_XLSX_TEMPLATES_FAIL, props<{ error: Error }>())

const getScopeTypes = createAction(ScopesActionTypes.GET_SCOPE_TYPES, emptyProps)
const getScopeTypesSuccess = createAction(ScopesActionTypes.GET_SCOPE_TYPES_SUCCESS, props<{ scopeTypes: string }>())
const getScopeTypesFail = createAction(ScopesActionTypes.GET_SCOPE_TYPES_FAIL, props<{ error: Error }>())

const getCustomFields = createAction(ScopesActionTypes.GET_CUSTOM_FIELDS, emptyProps)
const getTrafficSystem = createAction(ScopesActionTypes.GET_TRAFFIC_SYSTEM, emptyProps)
const getCustomFieldsSuccess = createAction(ScopesActionTypes.GET_CUSTOM_FIELDS_SUCCESS, props<{ customFields: string }>())
const getCustomFieldsFail = createAction(ScopesActionTypes.GET_CUSTOM_FIELDS_FAIL, props<{ error: Error }>())
const getTrafficSystemSuccess = createAction(ScopesActionTypes.GET_TRAFFIC_SYSTEM_SUCCESS, props<{ trafficSystem: string }>())
const getTrafficSystemFail = createAction(ScopesActionTypes.GET_TRAFFIC_SYSTEM_FAIL, props<{ error: Error }>())

const revertReplacementRoles = createAction(
  ScopesActionTypes.REVERT_REPLACEMENTS,
  props<{ scopeId: number, revertId: number }>()
)
const revertReplacementRolesSuccess = createAction(
  ScopesActionTypes.REVERT_REPLACEMENTS_SUCCESS,
  props<{ revertResponse: string }>()
)
const revertReplacementRolesFail = createAction(ScopesActionTypes.REVERT_REPLACEMENTS_FAIL, props<{ error: Error }>())

const getAllFeeItems = createAction(ScopesActionTypes.GET_ALL_FEE_ITEMS, emptyProps)
const getAllFeeItemsSuccess = createAction(ScopesActionTypes.GET_ALL_FEE_ITEMS_SUCCESS, props<{ feeItems: any[] }>())
const getAllFeeItemsFail = createAction(ScopesActionTypes.GET_ALL_FEE_ITEMS_FAIL, props<{ error: Error }>())

const getDynamicFieldSettings = createAction(ScopesActionTypes.GET_ALL_SCOPE_DYNAMIC_FIELD_SETTINGS);
const getDynamicFieldSettingsSuccess = createAction(ScopesActionTypes.GET_ALL_SCOPE_DYNAMIC_FIELD_SETTINGS_SUCCESS, props<{ settings: ScopeDynamicFieldSettingModel[] }>());
const getDynamicFieldSettingsFail = createAction(ScopesActionTypes.GET_ALL_SCOPE_DYNAMIC_FIELD_SETTINGS_FAIL , props<{ error: any }>());
const createDynamicFieldSetting = createAction(ScopesActionTypes.CREATE_SCOPE_DYNAMIC_FIELD_SETTING, props<{ setting: ScopeDynamicFieldSettingModel }>());
const createDynamicFieldSettingSuccess = createAction(ScopesActionTypes.CREATE_SCOPE_DYNAMIC_FIELD_SETTING_SUCCESS, props<{ setting: ScopeDynamicFieldSettingModel }>());
const createDynamicFieldSettingFail = createAction(ScopesActionTypes.CREATE_SCOPE_DYNAMIC_FIELD_SETTING_FAIL, props<{ error: any }>());
const updateDynamicFieldSetting = createAction(ScopesActionTypes.UPDATE_SCOPE_DYNAMIC_FIELD_SETTING, props<{ setting: ScopeDynamicFieldSettingModel }>());
const updateDynamicFieldSettingSuccess = createAction(ScopesActionTypes.UPDATE_SCOPE_DYNAMIC_FIELD_SETTING_SUCCESS, props<{ setting: ScopeDynamicFieldSettingModel }>());
const updateDynamicFieldSettingFail = createAction(ScopesActionTypes.UPDATE_SCOPE_DYNAMIC_FIELD_SETTING_FAIL, props<{ error: any }>());
const deleteDynamicFieldSetting = createAction(ScopesActionTypes.DELETE_SCOPE_DYNAMIC_FIELD_SETTING, props<{ id: number }>());
const deleteDynamicFieldSettingSuccess = createAction(ScopesActionTypes.DELETE_SCOPE_DYNAMIC_FIELD_SETTING_SUCCESS, props<{ id: number }>());
const deleteDynamicFieldSettingFail = createAction(ScopesActionTypes.DELETE_SCOPE_DYNAMIC_FIELD_SETTING_FAIL, props<{ error: any }>());

export const ScopeActions = {
  getMyScopes,
  getMyScopesFail,
  getMyScopesSuccess,
  getArchivedScopes,
  getArchivedScopesFail,
  getArchivedScopesSuccess,
  getFolders,
  getFoldersFail,
  getFoldersSuccess,
  getArchivedFolders,
  getArchivedFoldersFail,
  getArchivedFoldersSuccess,
  filterScopesSort,
  filterArchivedScopesSort,
  filterScopesPageInfo,
  filterArchivedScopesPageInfo,
  filterFoldersSort,
  filterArchivedFoldersSort,
  filterFoldersPageInfo,
  filterArchivedFoldersPageInfo,
  filterScopes,
  getMyScopesMappedColumns,
  getMyScopesMappedColumnsSuccess,
  getScopeMappedColumnsSuccess,
  filterFolders,
  getHighlights,
  getHighlightsFail,
  getHighlightsSuccess,
  searchDeliverables,
  searchDeliverablesFail,
  searchDeliverablesSuccess,
  searchComponents,
  searchComponentsFail,
  searchComponentsSuccess,
  getDeliverableSummaries,
  getDeliverableSummariesFail: getDeliverableSummariesFail,
  getDeliverableSummariesSuccess: getDeliverableSummariesSuccess,
  getDeliverables,
  getDeliverablesFail: getDeliverablesFail,
  getDeliverablesSuccess: getDeliverablesSuccess,
  getScopesByFolder,
  getScopesByFolderFail,
  getScopesByFolderSuccess,
  archiveScope,
  archiveScopeSuccess,
  archiveScopeFail,
  unarchiveScope,
  unarchiveScopeSuccess,
  unarchiveScopeFail,
  archiveSow,
  archiveSowSuccess,
  archiveSowFail,
  unarchiveSow,
  unarchiveSowSuccess,
  unarchiveSowFail,
  closeScope,
  closeScopeSuccess,
  closeScopeFail,
  deleteScope,
  deleteScopeSuccess,
  deleteScopeFail,
  deleteFolder,
  deleteFolderSuccess,
  deleteFolderFail,
  getAllFolders,
  getAllFoldersSuccess,
  getAllFoldersFail,
  getSecondParties,
  getSecondPartiesSuccess,
  getSecondPartiesFail,
  assignScopeToFolder,
  assignScopeToFolderSuccess,
  assignScopeToFolderFail,
  unassignScopeFromFolder,
  unassignScopeFromFolderSuccess,
  unassignScopeFromFolderFail,
  findRoles,
  findRolesSuccess,
  findRolesFail,
  setSelectedReplaceRole,
  setSelectedReplaceWithRole,
  replaceRoles,
  replaceRolesSuccess,
  replaceRolesFail,
  closeFindReplace,
  setReplaceSummaryConfirmation,
  getRateCardForScope: getRateCardForScope,
  getRateCardForScopeSuccess: getRateCardForScopeSuccess,
  getRateCardForScopeFail: getRateCardForScopeFail,
  getRateCard: getRateCard,
  getRateCardSuccess: getRateCardSuccess,
  getRateCardFail: getRateCardFail,
  getOfficeLocations,
  getOfficeLocationsSuccess,
  getOfficeLocationsFail,
  getScopeTemplates,
  getScopeTemplatesSuccess,
  getScopeTemplatesFail,
  getWordOutputTemplates,
  getWordOutputTemplatesSuccess,
  getWordOutputTemplatesFail,
  getXlsxOutputTemplates,
  getXlsxOutputTemplatesSuccess,
  getXlsxOutputTemplatesFail,
  getScopeTypes,
  getScopeTypesSuccess,
  getScopeTypesFail,
  getCustomFields,
  getCustomFieldsSuccess,
  getCustomFieldsFail,
  revertReplacementRoles,
  revertReplacementRolesFail,
  revertReplacementRolesSuccess,
  getAllFeeItems,
  getAllFeeItemsFail,
  getAllFeeItemsSuccess,
  getTrafficSystem,
  getTrafficSystemSuccess,
  getTrafficSystemFail,
  getDynamicFieldSettings,
  getDynamicFieldSettingsSuccess,
  getDynamicFieldSettingsFail,
  createDynamicFieldSetting,
  createDynamicFieldSettingSuccess,
  createDynamicFieldSettingFail,
  updateDynamicFieldSetting,
  updateDynamicFieldSettingSuccess,
  updateDynamicFieldSettingFail,
  deleteDynamicFieldSetting,
  deleteDynamicFieldSettingSuccess,
  deleteDynamicFieldSettingFail,
}
