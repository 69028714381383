import { Component, ElementRef, HostListener, SecurityContext, ViewChild } from '@angular/core'
import { EnvService } from '@envs/env-service'
import { environment } from '@envs/environment'
import { HeaderStyleService, HIDE_HEADER } from '@shared/services/header-style.service'
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router'
import _ from 'lodash'
import { DomSanitizer, Title } from '@angular/platform-browser'
import { AuthService } from '@core/service/auth.service'

@Component({
  selector: 'manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss'],
})
export class ManageComponent {
  baseJsUrl: string
  @ViewChild('iframe') iFrame: ElementRef

  constructor(private headerStyleService: HeaderStyleService,
              private envService: EnvService,
              private router: Router,
              private route: ActivatedRoute,
              private titleService: Title,
              private sanitizer: DomSanitizer,
              private authService: AuthService) {
    this.titleService.setTitle('Details | Admin | Scope')
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd && event.url === '/manage') {
        this.baseJsUrl = `${this.envService.baseJsUrl}/admin?host=${environment.baseHost}&childCompany=${this.authService.loggedInUser?.company.id}`;
        // Setting the iframe src this way forces a reload
        this.iFrame.nativeElement.src = this.sanitizer.sanitize(SecurityContext.URL, this.baseJsUrl)
      }
    });
  }

  ngOnInit() {
    let tab = this.route.snapshot.queryParamMap.get('tab')
    this.baseJsUrl = `${this.envService.baseJsUrl}/admin?host=${environment.baseHost}&childCompany=${this.authService.loggedInUser?.company.id}${tab ? '&tab=' + tab : ''}`
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    if (event.data === 'hideHeader') {
      this.headerStyleService.setHeaderClass(HIDE_HEADER);
    } else if (event.data === 'showHeader') {
      this.headerStyleService.setHeaderClass(null);
    } else if (event.data.action === 'navigate' && event.data.route) {
      this.router.navigate([event.data.route]);
    } else if (event.data.action === 'adminNavigate') {
      this.titleService.setTitle(_.startCase(_.camelCase(event.data.location)) + ' | Admin | Scope')
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: { 'tab': event.data.location }
        }
      )
    } else if (event.data.action === 'goToAccountSettings') {
      this.router.navigate(
        [`/account-setup/${event.data.id}`],
        {
          relativeTo: this.route,
          queryParams: { 'tab': 'details' }
        }
      )
    } else if (event.data.action === 'redirectToOauth2') {
      window.open(event.data.redirectUrl, "_self")
    }
  }
}
