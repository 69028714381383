import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core'
import { CommonModule } from '@angular/common';
import { ScopeUiTabComponent } from '@shared/components/ui-components/scope-ui-tab/scope-ui-tab.component';
import { ScopeUiTabsComponent } from '@shared/components/ui-components/scope-ui-tabs/scope-ui-tabs.component';
import { OutputEditorComponent } from '@shared/components/output-editor/output-editor.component';
import { ScopeOverviewService } from '@app/features/scope-overview/service/scope-overview.service';
import { OutputTemplate } from '@app/features/scope-overview/model/output-template.model';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BehaviorSubject, debounceTime, finalize, Subscription } from 'rxjs';
import { skip, switchMap } from 'rxjs/operators';
import { ScopeVersion } from '@app/core/model/scope-version';
import { User } from '@app/core/model/user.model';
import { untilDestroyed } from '@app/shared/utils/utils';
import { SharedModule } from '@shared/shared.module';
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogEventsService } from '@app/features/scope-overview/service/dialog-events.service';
import { HttpResponse } from '@angular/common/http';
import {
  SNACKBAR_LENGTH_LONG,
  SNACKBAR_LENGTH_SHORT,
  SnackbarEventType,
  SnackbarService,
} from '@shared/utils/snackbar.service';

@Component({
  selector: 'app-scope-output',
  standalone: true,
  templateUrl: './scope-output.component.html',
  imports: [
    CommonModule,
    ScopeUiTabComponent,
    ScopeUiTabsComponent,
    OutputEditorComponent,
    FormsModule,
    MatCheckboxModule,
    MatTooltipModule,
    SharedModule,
    ScopeUiInputComponent,
  ],
  styleUrls: ['./scope-output.component.scss'],
})
export class ScopeOutputComponent {
  private readonly destroy$;

  @Input() currentScope!: ScopeVersion
  @Input() loggedInUser!: User

  @Output() onPreviewExport: EventEmitter<OutputTemplate>

  outputTemplate!: OutputTemplate
  xlsxTemplate!: any
  showMoreInfo: boolean
  editName!: boolean
  private subscription: Subscription
  requestSubject = new BehaviorSubject<OutputTemplate>(this.outputTemplate);

  constructor(private scopeOverviewService: ScopeOverviewService,
              private dialog: MatDialog,
              private dialogEventsService: DialogEventsService,
              private snackbarService: SnackbarService,
              private cdr: ChangeDetectorRef) {
    this.destroy$ = untilDestroyed();
    this.showMoreInfo = false;
    this.onPreviewExport = new EventEmitter<OutputTemplate>()
  }

  ngOnInit(): void {
    this.initScopeTemplate()
    this.initXlsxTemplate()
    this.onChangeMasterTemplate()
    this.onUpdateTemplate()
  }

  initScopeTemplate() {
    this.scopeOverviewService.getScopeTemplate(this.currentScope.identity.id)
      .subscribe(template => {
        this.outputTemplate = template;
        this.cdr.detectChanges()
        this.requestSubject = new BehaviorSubject<OutputTemplate>(this.outputTemplate);
        this.requestSubject.pipe(
          this.destroy$(),
          skip(1),
          debounceTime(1500),
          switchMap((template: OutputTemplate) => this.sendRequest(template))
        ).subscribe();
      })
  }

  initXlsxTemplate() {
    if (this.currentScope.identity.xlsxTemplate != null) {
      this.scopeOverviewService.getXlsxTemplate(this.currentScope.identity.xlsxTemplate.id)
        .subscribe(template => {
          this.xlsxTemplate = template;
        })
    }
  }

  onChangeMasterTemplate() {
    this.subscription = this.dialogEventsService.dialogEventEmitter
      .subscribe((data: { key: { key: string; optionalData: { text: any; hasOverride: boolean } } }) => {
        let {
          key: { key, optionalData },
        } = data

        if (key === 'changeMasterTemplate') {
          this.scopeOverviewService.updateScopeTemplateByTemplate(this.currentScope.identity.id, optionalData.text)
            .subscribe({
              next: (res: any) => {
                this.scopeOverviewService.getScopeTemplate(this.currentScope.identity.id)
                  .subscribe(template => {
                    this.outputTemplate = template;
                    this.dialogEventsService.emitEvent({ key: 'appliedMasterTemplateUpdate', optionalData: { text: this.outputTemplate, hasOverride: false } })
                    this.snackbarService.showSnackbar('Template was updated successfully', SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
                  });
                this.dialog.closeAll();
              },
              error: (error) => {
                this.snackbarService.showSnackbar('An error occurred during action with master template', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
                this.dialog.closeAll();
              },
            });
        } else if (key === 'applyMasterTemplateChanges') {
          this.scopeOverviewService.updateScopeTemplateByCompanyTemplate(this.currentScope.identity.id)
            .subscribe({
              next: (res: any) => {
                this.scopeOverviewService.getScopeTemplate(this.currentScope.identity.id)
                  .subscribe(template => {
                    this.outputTemplate = template;
                    this.dialogEventsService.emitEvent({
                      key: 'appliedMasterTemplateUpdate',
                      optionalData: { text: this.outputTemplate, hasOverride: false },
                    });
                    this.snackbarService.showSnackbar('Template was updated successfully', SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS);
                  });
                this.dialog.closeAll();
              },
              error: (error) => {
                this.snackbarService.showSnackbar('An error occurred during action with master template', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR);
                this.dialog.closeAll();
              },
            });
        }
      })
  }

  onUpdateTemplate() {
    this.subscription = this.dialogEventsService.dialogEventEmitter
      .subscribe((data: { key: { key: string; } }) => {
        let { key: { key}, } = data
        if (key === 'updateTemplate') {
          this.updateTemplate()
          this.dialog.closeAll()
        }
      })
  }

  private sendRequest(template: OutputTemplate) {
    return this.scopeOverviewService.updateCompanyCustomOutputTemplateFromScope(template);
  }

  onAddBlock(event: any) {
    this.sendRequest(event).subscribe(template => {
      this.scopeOverviewService.getScopeTemplate(this.currentScope.identity.id)
        .subscribe(template => {
          this.outputTemplate = template;
          this.dialogEventsService.emitEvent({ key: 'appliedMasterTemplateUpdate', optionalData: { text: this.outputTemplate, hasOverride: false } })
        });
    })
  }

  updateTemplate() {
    this.requestSubject.next(this.outputTemplate);
  }

  updateTemp(event: any) {
    this.requestSubject.next(event);
  }

  previewExport() {
    this.onPreviewExport.emit(this.outputTemplate)
  }

  previewXlsxExport() {
    this.scopeOverviewService.exportScopeToXlsxTemplate(this.currentScope.identity.id).subscribe((res) => {
      this.downloadFile(res);
    });
  }

  downloadFile(res: HttpResponse<Blob>) {
    if (res.body != null) {
      const a = document.createElement("a");
      const fileName = res.headers.get('content-disposition');
      a.href = URL.createObjectURL(res.body);
      if (fileName != null) {
        a.download = fileName.split('filename=')[1].split(';')[0];
      }
      a.click();
    }
  }
}
