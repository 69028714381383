<div class='justify-start'>
  <button class='text-link small ml-8 mt-4' aria-label='Back to Manage' (click)='backToManage()'>
    <mat-icon class='material-symbols-rounded orange'>chevron_left</mat-icon>
    Back to Manage
  </button>
</div>
<div class='scope-field-container'>
  <scope-ui-tabs [fontSize]="'24px'" class='pt-4 scope-tabs'>
    <scope-ui-tab tabTitle="{{('scope.p' | lang)}} Fields">
      <div class='mt-6 scope-field-sub-container'>
        <div class='search-bar-container'>
          <div class='spacer'></div>
          <scope-ui-input
            [hasPlaceholder]='false'
            [inputPlaceholder]="''"
            [icon]="'search'"
            [cancelIcon]="'cancel'"
            [inputLabel]="'Search'"
            [inputClass]="'search-field'"
            (onCancel)='onCancelPressed()'
            (onInputChange)='onSearchKeyPressed($event)' />
          <button mat-flat-button class='create-modal-button' (click)='openCreateModal()'>+ New Field</button>
        </div>
        <ng-container class='py-3'>
          <scope-ui-table
            [loggedInUser]='loggedInUser'
            [dataSource]='mappedDynamicFieldDataSource$ | async'
            [displayedColumns]='dynamicFieldColumnsPrefsArray'
            [noPagination]='true'
            [tableClass]="'row-gaps'"
            [menuOptions]='dynamicFieldMenuOptions'
            [showToggleMenu]='true'
          ></scope-ui-table>
        </ng-container>
      </div>
    </scope-ui-tab>
  </scope-ui-tabs>
</div>
