import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { OutputTemplate } from '@app/features/scope-overview/model/output-template.model';
import { OutputField } from '@app/features/scope-overview/model/output-field.model';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@shared/pipe/index.module';
import { FormControl, FormsModule } from '@angular/forms';
import { CKEditorModule } from 'ckeditor4-angular';
declare const CKEDITOR: any;
import { BehaviorSubject, Subscription } from 'rxjs';
import { ScopeOverviewService } from '@app/features/scope-overview/service/scope-overview.service';
import { HttpResponse } from '@angular/common/http';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  OutputResourceObjects,
  OutputVariables,
  getDefinitionNameWidgets,
  getDefinitionNameVariables,
} from '@shared/utils/output-variables';
import { ScopeVersion } from '@app/core/model/scope-version';
import { ScopeUiModalComponent } from '@shared/components/ui-components/scope-ui-modal/scope-ui-modal.component';
import { ModalConfig } from '@core/model/modal-config.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ChangeScopeOutputModalComponent, ChangeScopeOutputModalConfig,
} from '@app/features/scope-overview/components/change-scope-output-modal/change-scope-output-modal.component';
import { Preference } from '@core/model/user-preferences.interface';
import { User } from '@core/model/user.model';
import { DialogEventsService } from '@app/features/scope-overview/service/dialog-events.service';
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component';
import { MatIconModule } from '@angular/material/icon';
import * as $ from 'jquery';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  SetBordersModalComponent, SetBordersModalConfig,
} from '@app/features/scope-overview/components/set-borders-modal/set-borders-modal.component';
import { SNACKBAR_LENGTH_SHORT, SnackbarEventType, SnackbarService } from '@shared/utils/snackbar.service';
import { DirectivesModule } from '@shared/directives/index.module'
import { ckeConfig } from '@shared/utils/ckeConfig';
import { clone, cloneDeep } from 'lodash';
import { trackById } from '@shared/utils/utils';
import { SortByOrderPipe } from '@shared/pipe/sort-by-order.pipe';


@Component({
  selector: 'app-output-editor',
  standalone: true,
  templateUrl: './output-editor.component.html',
  imports: [
    CommonModule, PipesModule, FormsModule, CKEditorModule, MatTooltipModule, ScopeUiInputComponent, MatIconModule, CdkDropList, CdkDrag, DirectivesModule, SortByOrderPipe,
  ],
  styleUrls: ['./output-editor.component.scss'],
})
export class OutputEditorComponent {
  @Input() outputTemplate!: OutputTemplate
  @Input() currentScope!: ScopeVersion
  @Input() currentUser!: User
  @Input() subject!: BehaviorSubject<OutputTemplate>
  @ViewChild('editor') editor: any
  @Output() onPreviewExport: EventEmitter<void>

  activeSection: OutputField
  previewLoading: boolean = false
  updateInProgress: boolean = false
  holdSelection: boolean
  skipFirstUpdate = true
  ckeditorInstance: any
  totalCustomVars: number = 0
  customVars: number = 0
  lineWidth: number = 0
  sortedBlocks: OutputField[] = []
  fields: OutputField[]

  private subscription: Subscription
  private changeMasterTemplateDialogConfig: MatDialogRef<ChangeScopeOutputModalComponent, any>
  private setBordersDialogConfig: MatDialogRef<SetBordersModalComponent, any>
  private autocompleteConfigured = false

  constructor(private cdr: ChangeDetectorRef, private scopeService: ScopeOverviewService, private snackbarService: SnackbarService, private dialog: MatDialog, private dialogEventsService: DialogEventsService) {
    this.initSection()
    this.onPreviewExport = new EventEmitter<void>()
  }

  ngOnInit() {
    this.setSortedBlocks()
    this.onMasterUpdate()
  }

  // temporarily not used due to absence of drag-n-drop on prod (ticket already reported)
  drop(event: CdkDragDrop<any>) {
    moveItemInArray(this.outputTemplate.outputFields, event.previousIndex, event.currentIndex);
    if (event.previousIndex !== event.currentIndex) {
      this.updateBlocksOrder(this.outputTemplate.outputFields);
    }
  }

  setSortedBlocks() {
    this.sortedBlocks = this.outputTemplate.outputFields
      .sort((a, b) => {
        return a.order < b.order ? -1 : a.order > b.order ? 1 : 0
      })
  }

  updateBlocksOrder(data: OutputField[]) {
    this.outputTemplate.outputFields.forEach(field => {
      this.sortedBlocks.forEach(f => {
        if (f.id == field.id) {
          field.order = this.sortedBlocks.indexOf(f)
        }
      });
    })
    this.setSortedBlocks();
    this.updateTemp();
  }

  @Output() myEvent = new EventEmitter();
  @Output() addBlock = new EventEmitter();

  updateTemp() {
    if (this.skipFirstUpdate) {
      if (this.ckeditorInstance) {
        this.ckeditorInstance.resetUndo();
      }
      this.skipFirstUpdate = false
      return
    }
    if (this.ckeditorInstance) {
      this.activeSection.content = this.ckeditorInstance.getData();
    }
    this.cdr.markForCheck()
    this.myEvent.emit(this.outputTemplate);
    this.updateInProgress = true;
    setTimeout(() => {this.updateInProgress = false;this.cdr.markForCheck()}, 3000)
  }

  EDITOR_TABS = [
    {
      name: "Preview data",
      active: false,
      icon: "preview",
      onClick: (tab: any) => this.openPreviewTab(tab)
    },
    {
      name: "Editor",
      icon: "edit",
      active: true,
      onClick: (tab: any) => this.openEditorTab(tab)
    }, {
      name: "Code",
      active: false,
      icon: "code",
      onClick: (tab: any) => this.openCodeTab(tab)
    }
  ]

  openCodeTab(tab: any) {
    if (!this.editor) {
      return
    }
    if (this.EDITOR_TABS.find(tab => tab.name === "Preview data").active) {
      this.editor.instance.execCommand('togglePreview');
    }
    if (!tab.active) {
      this.editor.instance.execCommand('source');
    }
    this.EDITOR_TABS.forEach(t => t.active = false);
    $('.cke_top').css('display', 'none');
    $('.cke_contents').css('min-height', '530px');
    $('#active-section').css('border-top', 'none');
    this.previewLoading = false;
    tab.active = true;
  }

  blockHidden(activeSection: OutputField, outputTemplate: OutputTemplate) {
    if (activeSection) {
      return !((!activeSection.children || activeSection.children.length == 0) && (activeSection.fieldType?.indexOf('FOOTER') == -1 || outputTemplate.documentTemplate == 'BLANK'))
    } else {
      return true
    }
  }

  dataReady() {
    if (!this.autocompleteConfigured) {
      this.configureScopeVariables(OutputVariables.scopeContentBlockValues())
      this.configureScopeResources(this.editor.instance, OutputResourceObjects.scopeValues())
      this.autocompleteConfigured = true
    }
  }

  namespaceLoaded() {
    OutputResourceObjects.names().forEach(function(name: any) {
      CKEDITOR.dtd[name] = CKEDITOR.dtd;
      CKEDITOR.dtd.$nonEditable[name] = 1;
      CKEDITOR.dtd.$object[name] = 1;
      CKEDITOR.dtd.$block[name] = 1;
      CKEDITOR.dtd['body'][name] = 1;
    })
  }

  onReady(event: any) {
    this.ckeditorInstance = event.editor
    setTimeout(() => {
      event.editor.setData(this.activeSection.content)
        this.ckeditorInstance.readOnly = !this.activeSection.editable;
    }, 1000)
    this.applyScopeAssetsStyles()
  }

  configureScopeResources(editor: any, resourceObjects: any) {
    resourceObjects.forEach((r: { id: any; }) => {
      const name = r.id;
      editor.filter.allow(name + "[!*]", name, true);
    })

    var itemTemplate = '<li data-id="{id}">' +
      '<div><strong class="item-title">{name}</strong></div>' +
      '<div><small>{description}</sm></div>' +
      '</li>';

    function matchCallback (pattern: any) {
      return function(text:any, offset:any){
        var match = text.slice(0, offset)
          .replaceAll(' ', ' ')
          .match(pattern);
        if (!match) {
          return null;
        }
        return {
          start: match.index,
          end: offset
        };
      }
    }

    var autocomplete = new CKEDITOR.plugins.autocomplete(editor, {
      textTestCallback: function textTestCallback(range: any) {
        if (!range.collapsed) {
          return null;
        }

        return CKEDITOR.plugins.textMatch.match(range, matchCallback(/\#\{([A-z ]|\})*$/));
      },
      dataCallback: this.dataCallback('#{ITEM_NAME}',resourceObjects),
      itemTemplate: itemTemplate,
    });

    // Override default getHtmlToInsert to enable rich content output.
    autocomplete.getHtmlToInsert = function (item: any) {
      return editor.widgets.registered[item.widget].template.source
    }
  }

  configureScopeVariables(variables: any) {
    let itemTemplate = '<li data-id="{id}">' +
      '<div><strong class="item-title">{name}</strong></div>' +
      '<div><small>{description}</sm></div>' +
      '</li>';
    let outputTemplate = '<variableplaceholder variable-key="{id}">{name}</variableplaceholder>';

    function textTestCallback  (range: any) {
      if (!range.collapsed) {
        return null;
      }
      return CKEDITOR.plugins.textMatch.match(range, matchCallback(/\$\{([A-z ]|\})*$/));
    };

    function matchCallback (pattern: RegExp) {
      return function<T extends string>(text:T, offset:any){
        var match = text.slice(0, offset)
          .replaceAll(' ', ' ')
          .match(pattern);
        if (!match) {
          return null;
        }
        return {
          start: match.index,
          end: offset
        };
      }
    }
    let clonedVariables = cloneDeep(variables);
    this.currentScope.companyScopeCustomFieldsDefinition?.customFields.filter(cf=>!cf.hidden).forEach(cf => {
      clonedVariables.push({
          name: cf.name,
          id:"scope.custom_field_" + cf.order,
          description: "custom field" + cf.order,
          supportsEntity: ['SCOPE'],
          supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
        }
      )
    })
    let autocomplete = new CKEDITOR.plugins.autocomplete(this.editor.instance, {
      textTestCallback: textTestCallback,
      dataCallback: this.dataCallback('${ITEM_NAME}', clonedVariables),
      itemTemplate: itemTemplate,
      outputTemplate: outputTemplate,
    });

    // Override default getHtmlToInsert to enable rich content output.
    autocomplete.getHtmlToInsert = function (item: any) {
      return this.outputTemplate.output(item);
    };
  }

  dataCallback(template: any, variables:any) {
    const templateContentStartIndex = template.indexOf('ITEM_NAME');
    const tempVars = variables;
    return function(matchInfo:any, callback: any){
      if (matchInfo.autocomplete.editor.widgets.widgetHoldingFocusedEditable) {
        if (matchInfo.query.startsWith("#{")) {
          let definitionName = getDefinitionNameWidgets(matchInfo);
          if (definitionName == null) {
            return;
          }
          variables = OutputResourceObjects.widgetValues(definitionName)
        } else if (matchInfo.query.startsWith("${")) {
          let definitionName = getDefinitionNameVariables(matchInfo);
          if (definitionName == null) {
            return;
          }
          variables = OutputVariables.scopeWidgetValues(definitionName)
        }
      } else {
      variables = tempVars;
      }
      var data = variables.filter(function (item:any) {
        let itemName = item.name.toLowerCase()
        let query = matchInfo.query.substr(templateContentStartIndex, matchInfo.query.length);
        return itemName.indexOf(query.toLowerCase()
          .trimStart()
          .replaceAll(' ', ' ')
        ) != -1;
      });

      callback(data);
    }
  }

  applyScopeAssetsStyles() {
    $(".cke_button__add_box_icon").parent().parent().addClass('insert-toolbar')
    $(".cke_button__add_box_icon").parent().parent().prepend("<label style='text-align: center;'>Scope Assets</label>");
    $(".cke_button__add_box_icon").parent().css({"display": "flex", "flex-direction": "column", "align-items": "center"});
    $(".cke_button__add_widget_icon").parent().css({"display": "flex", "flex-direction": "column", "align-items": "center"});
    $(".cke_button__add_box_icon").parent().append("<span style='color: white'>Widgets</span>");
    $(".cke_button__add_widget_icon").parent().append("<span style='color: white'>Variables</span>");
    $(".cke_button__signature").parent().parent().addClass('signature-toolbar')
    $(".cke_button__signature").parent().prepend("<label>Signature</label>");
  }

  position = [
    {key: "LEFT", name: "Align left", order: 1},
    {key: "RIGHT", name: "Align right", order: 2},
    {key: "CENTER", name: "Align center", order: 3},
    {key: "NONE", name: "Hide", order: 4}
  ];

  changeAlign(field: any, position: any) {
    field.content = position.key;
    this.updateTemp();
  };

  openEditorTab(tab: any) {
    if (this.EDITOR_TABS.find(tab => tab.name === "Code").active) {
      this.editor.instance.execCommand('source');
    }
    if (this.EDITOR_TABS.find(tab => tab.name === "Preview data").active) {
      this.editor.instance.execCommand('togglePreview');
    }
    $('.cke_top').css('display', 'inherit')
    $('.cke_contents').css('min-height', '420px');
    $('#active-section').css('border-top', 'none');
    this.EDITOR_TABS.filter(t => t.name !== "Editor").forEach(t => t.active = false);
    this.previewLoading = false;
    tab.active = true
  }

  setTotalVariablesCount() {
    const previewWidgets = this.editor ? this.editor.instance.document.find('.resourceobject-preview').count() : 0;
    const variableplaceholders = this.editor ? this.editor.instance.document.find('variableplaceholder').toArray()
      .filter(vp => (!(vp.getParents().find(c => c.hasClass('resourceobject-template') || c.hasClass('resourceobject-preview'))))).length : 0;
    this.totalCustomVars = variableplaceholders + previewWidgets;
    if (variableplaceholders > this.totalCustomVars && variableplaceholders % this.totalCustomVars === 0) {
      this.customVars = this.customVars % this.totalCustomVars;
    } else {
      this.customVars += 1;
    }
  }

  showLoad(total: number, vars: number) {
    setTimeout(() => {
      $(".line").css("width", this.lineWidth + "px");
      this.lineWidth += 20;
      if (vars % total === 0) {
        $(".line").css("width", "200px");
        setTimeout(() => {
          this.previewLoading = false;
          this.cdr.detectChanges();
        },1000);
        this.customVars = 0;
      }
    }, 1000);
  }

  loadPreview() {
    this.customVars = 0;
    this.lineWidth = 20;
    this.setTotalVariablesCount();
    if (this.totalCustomVars === 0) {
      this.previewLoading = false;
      this.cdr.detectChanges();
      return;
    }
    const _this = this;
    this.editor.instance.on('requestReady', () => {
      _this.setTotalVariablesCount();
      _this.showLoad(_this.totalCustomVars, _this.customVars);
    })
    setTimeout(() => {
      $(".preview-text").fadeOut(1000);
      $(".hidden-preview-text").delay(980).fadeIn(1000);
    }, 8000);
  }

  openPreviewTab(tab: any) {
    if (!this.editor) {
      return
    }
    if (this.EDITOR_TABS.find(tab => tab.name === "Code").active) {
      this.editor.instance.execCommand('source');
    }
    if (!tab.active) {
      this.previewLoading = true;
      this.loadPreview();
      setTimeout(() => {
        this.editor.instance.execCommand('togglePreview');
      }, 100)

    }
    this.EDITOR_TABS.forEach(t => t.active = false);
    $('.cke_top').css('display', 'none');
    $('.cke_contents').css('min-height', '530px');
    $('#active-section').css('border-top', '3px solid #484E5D');
    tab.active = true;
  }


  isWhitelisted(template: OutputTemplate){
    return false;
  }

  lockOrUnlockField(field: OutputField, lock: boolean) {}
  temporarilyDeleteField(field: OutputField){
    field.softDeleted = true;
    this.updateTemp()
  }
  restoreField(field: OutputField){
    field.softDeleted = false;
    this.updateTemp()
  }
  deleteField(field: OutputField){}

  showAddBordersModal(){
    const dialogConfig: SetBordersModalConfig = {
      currentScope: this.currentScope,
      outputTemplate: this.outputTemplate
    }

    this.setBordersDialogConfig = this.dialog.open(SetBordersModalComponent, {
      data: dialogConfig,
    })
  }

  previewExport(){
    if (!this.updateInProgress) {
      this.onPreviewExport.emit()
    } else {
      this.snackbarService.showSnackbar('Wait until template is updated', SNACKBAR_LENGTH_SHORT, SnackbarEventType.WARNING)
    }
  }
  downloadExport(){
    this.updateTemp()
    this.snackbarService.showPrioritisedSnackbar('Downloading... Please wait', SNACKBAR_LENGTH_SHORT, SnackbarEventType.INFO)
    setTimeout(() => {
      this.scopeService.exportScopeToDocx(this.currentScope.identity.id).subscribe((res) => {
        this.downloadFile(res);
      });
    }, 3000)

  }
  showDuplicateTemplateModal(){}
  uploadDocxBaseTemplateModal(){}

  showChangeScopeTemplate(){
    const dialogConfig: ChangeScopeOutputModalConfig = {
      currentScope: this.currentScope,
      user: this.currentUser,
      deliverables: this.currentScope.deliverables,
      deliverableColumns: [{ key: 'DELIVERABLE' }, { key: 'REVIEW_STATUS' }] as Preference[],
      title: `Complete Scope Review`,
      limitModalWidth: true,
      text: 'comment',
    }
    this.initSection()
    this.changeMasterTemplateDialogConfig = this.dialog.open(ChangeScopeOutputModalComponent, {
      data: dialogConfig,
    })
  }

  showAddContentBlockModal(){
      let _this = this
      let dialog = this.dialog.open(ScopeUiModalComponent, {
        data: new ModalConfig(
          `Add content block`,
          `Enter the name of new content block`,
          'Complete',
          undefined,
          function(form: FormControl) {
            const outputField = new OutputField();
            outputField.name = form.get('name')?.value;
            let order = _this.getOrder(_this.outputTemplate.outputFields);
            outputField.fieldType = 'CONTENT_BLOCK';
            outputField.order = order;
            outputField.editable = true;
            outputField.outputFieldBorders = null;
            outputField.content = '';
            outputField.disabled = false;
            outputField.headerRepeatable = false;
            outputField.footerRepeatable = false;
            _this.outputTemplate.outputFields.push(outputField);
            _this.addBlock.emit(_this.outputTemplate)
            dialog.close();
          },
          undefined,
          [{ name: 'name', control: new FormControl(''), type: 'text', label: '' }],
          false,
          false,
          null,
          function (form: FormControl, selections: any) {
            return !(form.get('name')?.value == "" || form.get('name')?.value == null);
          }
        ),
      })
  }

  getOrder(fields: OutputField[]): number {
    let order: number
    let sortedFields =  cloneDeep(fields).sort((a, b) => a.order - b.order);
    if (sortedFields.length > 1) {
      order = sortedFields[sortedFields.length - 2].order + 1
    } else {
      order = sortedFields.length
    }
    return order
  }

  setRepeatable(field: OutputField){}

  applyMasterTemplateChanges(){
    let _this = this
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Apply master template changes?`,
        `This will overwrite all your custom changes to this template`,
        'Apply',
        undefined,
        function (form: FormControl) {
          dialog.close()
          _this.dialogEventsService.emitEvent({ key: 'applyMasterTemplateChanges'})
          _this.activeSection = new OutputField()
          _this.activeSection.content = ''
        },
        undefined,
        []
      ),
    })
  }

  onMasterUpdate() {
    this.subscription = this.dialogEventsService.dialogEventEmitter
      .subscribe((data: { key: { key: string; optionalData: { text: OutputTemplate; hasOverride: boolean } } }) => {
        let {
          key: { key, optionalData },
        } = data
        if (key === 'appliedMasterTemplateUpdate') {
          this.outputTemplate = optionalData.text;
          this.outputTemplate.outputFields.forEach(field => field.active = false)
          this.initSection()
          this.cdr.markForCheck()
        }
      })
  }

  initSection() {
    this.activeSection = new OutputField()
  }

  setSectionActive(section: OutputField, outputFields: OutputField[]){
    if (this.holdSelection) {
      return
    }
    const mainTab = this.EDITOR_TABS.find(tab => tab.name === "Editor");
    mainTab?.onClick(mainTab);
    section.active = !section.active && section.editable && this.outputTemplate.documentTemplate !== 'PHM_TEMPLATE';
    if (section.id) {
      outputFields.filter(f => f.id !== section.id).forEach(field => field.active = false);
    } else {
      outputFields.filter(f => f.name !== section.name).forEach(field => field.active = false);
    }
    if (this.activeSection != null && (this.activeSection.id === section.id || this.activeSection.name === section.name)) {
      this.initSection()
    } else {
      this.skipFirstUpdate = true
      this.holdSelection = true
      this.activeSection = section;
      if (this.ckeditorInstance) {
        this.ckeditorInstance.setData(clone(this.activeSection.content))
        this.ckeditorInstance.readOnly = !this.activeSection.editable;
      }
      this.holdSection()
  }}

  holdSection() {
    setTimeout(() => {
      this.holdSelection = false
      this.cdr.detectChanges()
    }, 1000)
  }
  getFormattedDate(date: Date) {
    return this.outputTemplate.updatedTs.toLocaleDateString()
  }
  showLanguage(lang: string) {
    switch (lang) {
      case 'EN': {
        return 'English';
      }
      case 'ES': {
        return 'Spanish';
      }
      case 'DE': {
        return 'German';
      }
      case 'PR': {
        return 'Portuguese';
      }
      case 'FR': {
        return 'French';
      }
    }
    return 'Unknown';
  }
  downloadFile(res: HttpResponse<Blob>) {
    if (res.body != null) {
      const a = document.createElement("a");
      const fileName = res.headers.get('content-disposition');
      a.href = URL.createObjectURL(res.body);
      if (fileName != null) {
        a.download = fileName.split('filename=')[1].split(';')[0];
      }
      a.click();
    }
  }

  protected readonly ckeConfig = ckeConfig;
  protected readonly trackById = trackById;
}
